<template>
  <div class="vx-row test-groups-table" id="test-groups-table">
    <vs-col vs-w="12">
      <div class="pt-4 pb-base vs-con-loading__container">
        <vs-table
          :search="true"
          :hoverFlat="true"
          @mouseover="() => undefined"
          @mouseup="() => undefined"
          :data="testGroups"
          :max-items="15"
          ref="table"
          :no-data-text="lang.general.noDataText[languageSelected]"
        >
          <!-- pagination
            @change-page="handleChangePage"
            :total="total"-->
          <template slot="header">
            <vs-button
              v-if="isAuthAux('konecta.test_groups.add')"
              id="btn-create-group"
              class="float-right mb-3"
              @click="openAddTestPopup(null)"
            >
              {{
                lang.botMaker.testGroups.view.createTestGroup[languageSelected]
              }}
            </vs-button>
          </template>
          <template slot="thead">
            <vs-th vs-w="1" key="testSuite">{{
              lang.botMaker.testGroups.table.groups.testSuite[languageSelected]
            }}</vs-th>
            <vs-th key="testDescription">{{
              lang.botMaker.testGroups.view.description[languageSelected]
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="indextr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              class="bg-white"
              height="60px"
            >
              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>
              <vs-td :data="data[indextr].description">
                {{ data[indextr].description }}
              </vs-td>
              <vs-td>
                <div>
                  <ul class="horizontal-nav">
                    <li
                      class="navbar-item cursor-pointer"
                      @click="selectTestGroup(indextr)"
                      v-if="isAuthAux('konecta.test_groups.list')"
                      :id="`detail-btn-${indextr}`"
                    >
                      <feather-icon
                        icon="ListIcon"
                        class="inline-block mr-2"
                        svgClasses="w-8 h-8"
                      />
                    </li>
                    <li
                      class="navbar-item cursor-pointer"
                      @click="openAddTestPopup(data[indextr])"
                      v-if="isAuthAux('konecta.test_groups.edit')"
                      :id="`edit-btn-${indextr}`"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="inline-block mr-2"
                        svgClasses="w-8 h-8"
                      />
                    </li>
                    <li
                      class="navbar-item cursor-pointer"
                      @click="deleteGroupConfirmation(data[indextr])"
                      v-if="isAuthAux('konecta.test_groups.delete')"
                      :id="`delete-btn-${indextr}`"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="inline-block mr-2"
                        svgClasses="w-8 h-8"
                      />
                    </li>
                    <li
                      class="navbar-item cursor-pointer"
                      @click="excecuteTestGroup(data[indextr]._id)"
                      v-if="isAuthAux('konecta.test_groups.execute')"
                      :id="`execute-btn-${indextr}`"
                    >
                      <feather-icon
                        icon="PlayCircleIcon"
                        class="inline-block mr-2"
                        svgClasses="w-8 h-8"
                      />
                    </li>
                  </ul>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- <vs-pagination
            class="mt-2 float-right"
            v-if="testGroups.length"
            :value="1"
            :total="Math.ceil(testGroups.length / 15)"
          /> -->
      </div>
    </vs-col>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'botTest',
  mixins: [staffManagementMixin],
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
    // EmptyTable: () => import('../../components/EmptyTable.vue'),
  },
  props: {
    openAddTest: Function,
    excecuteTestGroup: Function,
    deleteGroupConfirmation: Function
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    ...mapGetters('botMaker', ['testGroups', 'botId', 'bot'])
  },
  methods: {
    ...mapActions('botMaker', ['SET_TEST_GROUP']),
    openAddTestPopup(testGroup) {
      this.openAddTest(testGroup)
    },
    async selectTestGroup(testGroupIndex) {
      this.$vs.loading()
      await this.SET_TEST_GROUP(this.testGroups[testGroupIndex]._id)
      this.$vs.loading.close()
    }
  },
  async mounted() {}
}
</script>

<style lang="scss">
#test-group {
  .title {
    margin-bottom: 20px;
  }
}
</style>

<style>
.vs-table--tbody {
  z-index: 1 !important;
  min-width: 0% !important;
}

.vs-table--tbody-table {
  min-width: 0% !important;
}
</style>

<style scoped>
.center {
  margin: auto;
  width: 100%;
}
</style>

<style lang="scss">
.test-groups-table {
  margin: 30px 0px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  #btn-create-group {
    margin-left: 0px !important;
    margin-right: auto;
  }
  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  .feather {
    width: 16px !important;
    height: 16px !important;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }

  .vs-pagination--buttons,
  .item-pagination {
    width: 30px;
    height: 30px;
  }
  .item-pagination {
    font-size: 0.8rem;
  }

  .con-vs-pagination {
    padding: 7px;
  }
  .horizontal-nav {
    display: flex;
    float: right;
    margin-right: 0px;
    .navbar-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 10px;

      .con-vs-tooltip {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .feather-icon {
          transition: all 0.3s;
          .feather {
            width: 20px !important;
            height: 20px !important;
          }

          &:hover,
          &.active {
            transform: scale(1.2);
            color: rgba(var(--vs-primary), 1) !important;
          }
        }

        .material-icons,
        .material-icons-outlined {
          font-size: 24px;
          transition: all 0.3s;
          &:hover,
          &.active {
            transform: scale(1.2);
            color: rgba(var(--vs-primary), 1) !important;
          }
        }
      }
    }
  }
}
</style>